.finalScore {
  position: absolute;
  top: 3.5rem;
  z-index: 1;
  font-weight: bold;
  color: var(--main-font-color);
  text-shadow: 0 0 15px var(--primary-color);
  font-family: var(--main-font-family);
  font-size: var(--question-font-size);
  animation: bump 1s ease-in-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }

  25% {
    opacity: 1;
    transform: scale(1.1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.highScoresBtn {
  position: absolute;
  top: 8rem;
  z-index: 1;
  background-color: var(--secondary-bg-color);
  font-size: var(--high-score-btn-font-size);
  font-weight: 700;
  color: var(--secondary-font-color);
  box-shadow: 4px 4px 15px var(--primary-color);
  padding: .1rem 1rem;
  border: 1px solid var(--magenta);
  border-radius: 1.5rem;
  cursor: pointer;
}

.highScoresBackground {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background-color: var(--alternative-bg-color);
}

.highScoresCanvas {
  position: absolute;
  width: 18rem;
  height: 18rem;
  background-color: var(--main-font-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  box-shadow: 30px 30px 80px var(--primary-color) inset,
    10px 10px 30px var(--main-font-color);
  padding: 2rem;
  color: var(--primary-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: appear 1s forwards;
}

@media screen and (min-width: 414px) {
  .highScoresCanvas {
    width: 20.5rem;
    height: 20.5rem;
  }
}

@media screen and (min-width: 600px) {
  .highScoresCanvas {
    width: 29.2rem;
    height: 29.2rem;
  }
}

.highScoresTitle {
  margin: 3rem 0 1.5rem;
  font-size: var(--high-score-title-font-size);
  font-weight: bold;
  text-transform: uppercase;
  filter: drop-shadow(0 0 10px var(--main-font-color));
}

.score {
  font-size: var(--high-score-font-size);
  font-weight: bold;
  text-transform: uppercase;
  filter: drop-shadow(0 0 .5px var(--primary-color));
}

.scoreEurope {
  color: var(--violet)
}

.scoreAsia {
  color: var(--fuchsia)
}

.scoreAfrica {
  color: var(--ocean)
}

.scoreAmericas {
  color: var(--orange)
}

.highScoresOkay {
  margin: 1.5rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
  cursor: pointer;
  border: 2px solid var(--primary-color);
  border-radius: 2rem;
  box-shadow: 2px 2px 5px var(--primary-color) inset,
    4px 4px 15px var(--primary-color);
  font-size: var(--high-score-font-size);
  font-weight: bold;
}

.highScoresOkay:hover {
  color: var(--main-font-color);
  background-color: var(--primary-color-hover);
  box-shadow: 2px 2px 5px var(--main-font-color) inset,
    5px 5px 15px var(--primary-color);
  -webkit-box-shadow: 2px 2px 5px var(--main-font-color) inset,
  5px 5px 15px var(--primary-color);
  border: 2px solid var(--main-font-color)
}

@keyframes appear {
    from {
      opacity: 0;   
    }
    to {
      opacity: 1;   
    }
  }

.star {
  position: absolute;
  top: 1.8vh;
  left: 3.6rem;
  z-index: 1;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  filter: drop-shadow(0 0 10px black);
  cursor: pointer;
  opacity: 0;
  animation: appear .2s ease-in forwards;
}
