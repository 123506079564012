.mapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;  opacity: 0;
  animation: appear 2s 1s forwards;
}

@keyframes appear {
    from {
      opacity: 0;   
    }
    to {
      opacity: 1;   
    }
  }
