.newGameBtn {
  position: absolute;
  top: 47vh;
  z-index: 1;
  background-color: var(--transparent-bg-color);
  font-size: var(--country-font-size);
  font-weight: 700;
  color: var(--main-font-color);
  border: 2px solid var(--main-font-color);
  cursor: pointer;
  padding: .7rem 2rem;
  border-radius: 2rem;
  box-shadow: 2px 2px 10px var(--main-font-color) inset,
    4px 4px 15px var(--primary-color);
  opacity: 0;
  animation: appear 1s ease-in forwards;
}

.newGameBtn:hover {
  background-color: var(--primary-color-hover);
}

@keyframes appear {
    from {
      opacity: 0;   
    }
    to {
      opacity: 1;   
    }
  }
