  /* For the intro animation I used ideas from:
  https://css-tricks.com/animating-with-clip-path/ - animating with clip-path
  https://www.youtube.com/watch?v=zadj2i5wUyc - animating with gradients by Colt Steele */

  /* creates a circle shaped "window"*/
  .introContainer {
    position: absolute;
    align-self: center;
    width: 18rem;
    height: 18rem;
    clip-path: circle(50% at 50% 50%);
  }

  /* moving an oversized background creates the moving sun effect */
  .intro {
    position: absolute;
    top: -5rem;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(circle, white 5%, var(--primary-color) 50%);
    background-size: 400%;
    animation: moveBackground 7s ease-in-out infinite;
  }

  @media screen and (min-width: 414px) {
    .introContainer{
      width: 24rem;
      height: 24rem;
    }
  }

  @media screen and (min-width: 600px) {
    .introContainer {
      width: 33rem;
      height: 33rem;
    }
  }

  /* moving the oversized background */
  @keyframes moveBackground {
    0% {
      background-position: right;
    }

    100% {
      background-position: left;
    }
    
  }